<template>
  <div></div>
</template>

<script>
import wx from "weixin-js-sdk";
export default {
  data() {
    return {};
  },
  mounted() {
    // 判断是否在微信环境
    const isWeixin = /MicroMessenger/i.test(navigator.userAgent);
    // 判断是否在微信小程序环境
    const isMiniProgram = /miniProgram/i.test(
      navigator.userAgent.toLowerCase()
    );
    var firstSession = sessionStorage.getItem("ygjSession");
    if (firstSession) {
      if (isWeixin && isMiniProgram) {
        // 如果在小程序内打开文件
        wx.miniProgram.navigateTo({
          url: "/pages/teacher/index",
        });
      }
    } else {
      sessionStorage.setItem("ygjSession", "first");
    }

    let self = this;
    console.log("path", this.$route);

    let url = "/api/Mobile/ORG/Teacher/Login/" + self.$route.query.guid;
    if (process.env.NODE_ENV === "development"&&!self.$route.query.guid) {
      // 处理开发环境逻辑
      url =
        "/api/Mobile/ORG/Teacher/Login/8CC13776-BDD5-48DC-8296-213D21F76651";
    }
    console.log("url", url);

    this.whale.remote.getResult({
      url: url,
      // guid=61222B8F-8389-4A5C-BDEB-CD1830493980&mrl=/mat/bill/new?tp=1
      // data: 'A1A2A300-0000-6666-5555-C5D79DD2A001',
      completed(m) {
        console.log(m);
        localStorage.setItem("loginToken", m.jwt.token);
        self.whale.user.set(m.user);
        localStorage.setItem("tokenExpires", m.jwt.ExpiresLong);
        if (self.$route.query.mrl) {
          // let arr = self.$route.query.mrl.split('?')
          // let arr1 = arr[1].split('=')
          // console.log(arr, arr1)
          // let obj={

          // }
          // console.log('obj',Object.assign(obj, arr1[0], arr1[1]))
          // self.$router.push({ path: arr[0], query: { arr1[0]: arr[1] } })
          self.$router.push(self.$route.query.mrl);
        } else {
          self.$router.push("/");
        }
      },
    });
  },
  // mounted() {
  //     let self = this;
  //     console.log('path',this.$route.query)
  //     console.log('self.$router.query.guid', self.$route.query.guid, this.$route.query.guid)
  //     this.whale.remote.getResult({
  //         url: "/api/Mobile/ORG/Teacher/Login/" + self.$route.query.guid,
  //         // 61222B8F-8389-4A5C-BDEB-CD1830493980
  //         // data: 'A1A2A300-0000-6666-5555-C5D79DD2A001',
  //         completed(m) {
  //             console.log(m)
  //             localStorage.setItem("loginToken", m.jwt.token);
  //             self.whale.user.set(m.user);
  //             localStorage.setItem("tokenExpires", m.jwt.ExpiresLong);
  //             self.$router.push("/")
  //         }
  //     })
  // },
  methods: {},
};
</script>

<style lang="less" scoped></style>